$(function() {
	let $body = $('body');

	$('a[href="#"]').on('click', ev => {
		ev.preventDefault();
	});

	$('[data-submit]').on('click', ev => {
		$('[name="submit"]').val($(ev.currentTarget).data('submit'));
	});

	$body.on('click', '.js-confirm', ev => {
		let $this = $(ev.currentTarget),
			msg = $this.data('confirm-msg');

		if(typeof msg === 'undefined') {
			msg = 'Är du säker?';
		}

		if(!confirm(msg)) {
			ev.preventDefault();
			ev.stopImmediatePropagation();
		}
	});

	$body.on('click', () => {
		$body.removeClass('userMenu_open');
	});

	$('.navMenuButton').on('click', () => {
		$('body').toggleClass('navMenu_open');
	});

	$('.userIcon').on('click', e => {
		e.stopPropagation();
		$body.toggleClass('userMenu_open');
	});

	$('button[type="submit"]').on('click', e => {
		$('.loader').show();
	});

	$(document).ajaxComplete(() => {
		$('.loader').hide();
	});

	let $datepicker = $('.datepicker');
	$datepicker.datepicker({ dateFormat: 'yy-mm-dd' });
	$('<i class="dp-date fa-calendar"></i>').insertAfter($datepicker);

	/**
	 * @returns {boolean}
	 */
	$.expr[':'].Contains = function(a, i, m) {
		return $(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
	};

	$body.on('click', '[data-logo]', ev => {
		let $this = $(ev.currentTarget);

		$.ajax({
			url: '/admin/logos/delete',
			type: 'POST',
			data: {
				logo: $this.data('logo')
			}
		}).done(() => {
			$this.remove();
		});
	});

	/**
	 * Event to filter a specific list, items to filtered are defined in data-search=".selector"
	 * Example: <input type="search" data-search=".grid-result .grid" />
	 */
	$body.on('input', '[data-search]', ev => {
		let $this = $(ev.currentTarget),
			$target = $($this.data('search')),
			search = $this.val();

		$target.each(function(k, v) {
			let $this = $(v),
				searchOK = !search || $this.is(":Contains('"+search+"')");

			$this.toggle(true === searchOK);
		});
	});

	/**
	 * Scroll page to specific offset
	 * @param offset: $(selector).offset().top
	 * @param time: animation time
	 * @param callback: execute something when target is reached
	 * @param target: what to scroll
	 */
	$.scrollT = function(offset = 0, time = 400, callback = false, target = 'html,body') {
		let complete = false;
		$(target).animate({ scrollTop: offset }, time,
			function() {
				if(!complete){ complete = true;
					if(typeof callback === 'function')
						callback();
				}
			});
	};

	$.fn.scrollT = function(offset=0, time=400, callback=false) {
		$.scrollT(offset, time, callback, this);
		return this;
	};

	$('form', '.auth__form:not(.auth--recovery)').on('submit', ev => {
		let $this = $(ev.currentTarget),
			$submit = $this.find('[data-submit="login"]');

		ev.preventDefault();

		$submit.prop('disabled', true).find('.submit__spinner').removeClass('hidden');

		$.ajax({
			url: '/account/login',
			method: 'POST',
			data: $(ev.currentTarget).serialize(),
			dataType: 'json'
		}).done(response => {
			if('redirect' in response.payload) {
				window.location.href = response.payload.redirect;
			} else {
				for(let key in response.messages) {
					if(!response.messages.hasOwnProperty(key)) continue;
					information.generate(response.messages[key]);
				}

				setTimeout(() => {
					$submit.prop('disabled', false).find('.submit__spinner').addClass('hidden');
				}, 200);
			}
		});
	});

	$('#build-container').sortable({
		items: '.build',
		containment: '#main'
	});

	// Find and initiate sorters
	window.Sorter.find();

	$('#contractors')
		.chosen({ width: '100%' })
		.on('change', function(ev, obj) {
			if('selected' in obj) $('body').trigger('addCompany.oc', obj.selected);
			else if('deselected' in obj) {
				$(`[data-company="${obj.deselected}"]`).remove();
			}
		});

	$('.dropzone').dragDrop({
		maxAmount: 10,
		placeholder: 'Drag och släpp, eller välj filer att ladda upp.'
	});

	// "Create Project"-sign
	(function() {
		let $build = $('[data-width]'),
			currentBuild = null;

		if($build.length && $build.data('width').toString().length) {
			currentBuild = new window.Build();
		}

		// Open edit mode
		$('body')
			.on('click', '#build-edit', ev => {
				ev.preventDefault();

				if(currentBuild) {
					currentBuild.$.toggleClass('build--modify');
					$(ev.currentTarget).toggleClass('active');
				}
			})
			.on('click', '#build-preview', ev => {
				ev.preventDefault();

				// Preview button when viewing a project
				if(!currentBuild) {
					let $build = $('.build-container');
					if($build.length) {
						let canvasWidth = $build.width(),
							canvasHeight = $build.outerHeight(true),
							winHeight = $(window).height() - 120;

						mask.setContent($build.clone());

						if(canvasHeight > winHeight) {
							canvasWidth *= winHeight / canvasHeight;
						}

						mask.$inner.prepend('<h4>Översiktsbild av skylt</h4>');
						mask.$wrapper.css('width', canvasWidth);
						mask.show();
					}
					return;
				}

				$(ev.currentTarget).toggleClass('fa-expand fa-compress active');

				let canvasWidth = currentBuild.$.width(),
					canvasHeight = currentBuild.$.outerHeight(true),
					winHeight = $(window).height();

				if(currentBuild.$.is('[style]')) {
					currentBuild.$.removeAttr('style');
				} else {

					if(canvasHeight > winHeight) {
						canvasWidth *= winHeight / canvasHeight;
					}

					currentBuild.$.css('width', canvasWidth);
					$.scrollT(currentBuild.$.offset().top);
				}
			});

		$('.styledRadio').on('change', ev => {
			let $this = $(ev.currentTarget),
				$container = $('#build-container');

			if(currentBuild && !currentBuild.isEmpty() && !confirm("Är du säker på att du vill byta bredd efter att du påbörjat skylten?")) {
				$(`#width-${currentBuild.width}`).prop('checked', true);
				return false;
			}

			$container.empty();
			$container.data('width', $(ev.currentTarget).val());

			currentBuild = new window.Build($container);

			$('#build-width').val(currentBuild.width);
		});
	})();

	$('#project').on('submit', ev => {
		let $this = $(ev.currentTarget),
			logos = {};

		// ev.preventDefault();

		$this.find('.build').each((k, v) => {
			let dex = k + 1;

			logos[dex] = {};

			$(v).find('.block').each((k, v) => {
				let $this = $(v),
					$img = $this.find('img'),
					[width, height] = $this.data('dimensions').split('x');

				let src = $img.attr('src'),
					logo = {
						id: $img.data('id') || null,
						data: null,
						width, height
					};

				if(logo.id === null && typeof src !== 'undefined') {
					logo.data = src; // .replace(/^data:image\/.*?;base64,/, '')
				}

				logos[dex][k + 1] = logo;
			});
		});

		let $logos = $('#logos');
		$logos.length && $logos.remove();

		$('<input type="hidden" name="logos" id="logos" />').val(JSON.stringify(logos)).appendTo($this);
	});

	$('.js-chosen').chosen({ width: '100%' });

	class Company {
		constructor() {
			this.$target = $('#entrepreneurs');
			this.$row = $('	<div class="grid list grid-align-middle">\
								<div class="col col-3" data-label="Företagsnamn"></div>\
								<div class="col col-3" data-label="Kontaktperson">\
									<select class="js-contact-user">\
										<option value="" disabled selected>Välj en kontaktperson ...</option>\
									</select>\
								</div>\
								<div class="col col-3" data-append="phone" data-label="Telefonnummer"></div>\
								<div class="col col-3" data-append="email" data-label="E-postadress"></div>\
							</div>');
			this.$option = $('<option />');

			this.events();
		}

		events() {
			$('body').on('addCompany.oc', (ev, id) => {
				$.ajax({
					url: '/companies/retrieve/' + id,
					method: 'GET',
					dataType: 'json'
				}).done(response => {
					let $company = this.$row.clone().attr('data-company', response.company.id);

					$.each($company.find('.col'), (k, v) => {
						let $v = $(v);

						switch(k) {
							case 0:
								$v.text(response.company.name);
								break;
							case 1:
								let $select = $v.find('select').attr('name', `contact_user_id[${response.company.id}]`);

								for(let key in response.users) {
									if(!response.users.hasOwnProperty(key)) continue;
									let user = response.users[key];

									this.$option.clone()
										.val(user.id)
										.text(user.name)
										.appendTo($select);
								}
								break;
						}
					});

					this.$target.append($company);
				});
			});

			this.$target.on('change', '.js-contact-user', ev => {
				let $this = $(ev.currentTarget),
					$grid = $this.closest('.grid');

				$.ajax({
					url: '/companies/contacts/' + $this.val(),
					method: 'GET',
					dataType: 'json'
				}).done(user => {
					$grid.find('[data-append="phone"]').text(user.phone);
					$grid.find('[data-append="email"]').text(user.email);
				});
			});
			
			$('#new-company').on('click', ev => {

				mask
					.setSize('large')
					.setContent('<h4>En entreprenör saknas</h4>\
									<div class="content">\
										<p class="ingress">Hittar du inte din under-entreprenör? Meddela oss med den information du har så letar vi upp dem!</p>\
										<form action="" method="POST">\
											<div class="form-input">\
												<label>\
													<span>Företagsnamn</span>\
													<input type="text" name="name" />\
												</label>\
											</div>\
											<div class="form-input">\
												<label>\
													<span>Kontaktperson</span>\
													<input type="text" name="contact" />\
												</label>\
											</div>\
											<div class="form-input">\
												<label>\
													<span>Telefonnummer</span>\
													<input type="text" name="phone" />\
												</label>\
											</div>\
											<div class="form-input form-select">\
												<label>\
													<span>Övrig information</span>\
													<textarea name="extra" placeholder="Förse oss med all information du har om företaget för att underlätta för oss ..." />\
												</label>\
											</div>\
											<div class="hooks">\
												<button type="submit" class="btn">Skicka</button>\
											</div>\
										</form>\
									</div>');

				mask.$inner.find('form')
					.off('.oc')
					.on('submit.oc', ev => {
						ev.preventDefault();

						$.ajax({
							type: 'POST',
							url: '/companies/request-new',
							data: $(ev.currentTarget).serialize()
						}).done(() => {
							information.success({ content: 'Vi har tagit emot din förfrågan och meddelar er när företaget är inlagt.' });
							mask.hide();
						});
					});

				mask.show();
			});
		}
	}
	new Company();

	$('[data-view-password]').on('click', ev => {
		let $this = $(ev.currentTarget),
			$input = $this.closest('[data-password]').find('input').eq(0);

		$input.attr('type', $input.attr('type') === 'text' ? 'password' : 'text');
		$this.toggleClass('fa-eye fa-eye-slash');

		ev.preventDefault();
	});

	$('[data-save]').on('click', ev => {
		let $this = $(ev.currentTarget);

		$this.toggleClass('fa-star fa-star-o');

		$.ajax({
			type: 'POST',
			url: '/companies/save',
			data: {
				favorite: $this.data('save'),
				save: $this.hasClass('fa-star')
			}
		})
	});

	let $tabContents = $('.tab-content', '.tab-contents');
	$('.tabs').on('click', 'li', function(ev) {
		let $this = $(this),
			index = $this.index();

		$this.siblings().removeClass('active');
		$this.addClass('active');

		$tabContents.hide();
		$tabContents.eq(index).show();
	});

	$('[name="project_contact"]').on('change', ev => {
		let $this = $(ev.currentTarget);
		$('#contact-person').toggleClass('hidden', $this.val().length !== 0);
	});


	if(ctrl.messages) {
		for(let key in ctrl.messages) {
			if(!ctrl.messages.hasOwnProperty(key)) continue;
			information.generate(ctrl.messages[key]);
		}
	}

	/** Custom Functions **/
	let scrollbarWidth = 0;
	$.getScrollbarWidth = function() {
		if ( !scrollbarWidth ) {
			var $div = $('<div />')
				.css({ width: 100, height: 100, overflow: 'auto', position: 'absolute', top: -1000, left: -1000 })
				.prependTo('body').append('<div />').find('div')
				.css({ width: '100%', height: 200 });
			scrollbarWidth = 100 - $div.width();
			$div.parent().remove();
		}
		return scrollbarWidth;
	};

	if (!String.prototype.format) {
		String.prototype.format = function() {
			let args = arguments;
			return this.replace(/{(\d+)}/g, (match, number) => {
				return typeof args[number] !== 'undefined' ? args[number] : match;
			});
		};
	}

	$('[data-approve]').on('click', ev => {
		let $this = $(ev.currentTarget),
			action = $this.data('approve'),
			accept = action === 'accept',
			uuid = $this.closest('.hooks').data('uuid');

		const ajax = () => {
			console.log(mask.$inner.find('textarea'), mask.$inner.find('textarea').val());
			$.ajax({
				type: 'POST',
				url: '/projects/approve',
				data: {
					uuid, action,
					comment: !accept ? mask.$inner.find('textarea').val() : null
				}
			}).done(() => {
				if(accept) {
					information.success({ content: 'Du har godkänt skylten! För att se den igen, kan du logga in.' });
				} else {
					information.notice({ content: 'Du har nekat skylten!' });
				}
				// information[accept ? 'success' : 'notice']({ content: `Du har ${accept ? 'godkänt' : 'nekat'} din plats på skylten.` });
				!accept && mask.hide();

				$this.closest('.hooks').remove();
			});
		};

		if(!accept) {
			mask
				.setSize('large')
				.setContent('<h4>Neka platsen</h4>\
								<div class="content">\
									<form action="" method="POST">\
										<div class="form-input form-select">\
											<label>\
												<span>Kommentar</span>\
												<textarea name="extra" placeholder="Skriv en anledning till varför ni vill neka er plats på skylten ..." />\
											</label>\
										</div>\
										<div class="hooks">\
											<button type="submit" class="btn btn--abort">Neka plats</button>\
										</div>\
									</form>\
								</div>');

			mask.$inner.find('form')
				.off('.oc')
				.on('submit.oc', ev => {
					ev.preventDefault();

					ajax();
				});

			mask.show();
		} else ajax();
	});

	(() => {

		function atvImg(){

			var d = document,
				de = d.documentElement,
				bd = d.getElementsByTagName('body')[0],
				htm = d.getElementsByTagName('html')[0],
				win = window,
				imgs = d.querySelectorAll('.atvImg'),
				totalImgs = imgs.length,
				supportsTouch = 'ontouchstart' in win || navigator.msMaxTouchPoints;

			if(totalImgs <= 0){
				return;
			}

			// build HTML
			for(var l=0;l<totalImgs;l++){

				var thisImg = imgs[l],
					layerElems = thisImg.querySelectorAll('.atvImg-layer'),
					totalLayerElems = layerElems.length;

				if(totalLayerElems <= 0){
					continue;
				}

				while(thisImg.firstChild) {
					thisImg.removeChild(thisImg.firstChild);
				}

				var containerHTML = d.createElement('div'),
					shineHTML = d.createElement('div'),
					shadowHTML = d.createElement('div'),
					layersHTML = d.createElement('div'),
					layers = [];

				thisImg.id = 'atvImg__'+l;
				containerHTML.className = 'atvImg-container';
				shineHTML.className = 'atvImg-shine';
				shadowHTML.className = 'atvImg-shadow';
				layersHTML.className = 'atvImg-layers';

				for(var i=0;i<totalLayerElems;i++){
					var layer = d.createElement('div'),
						linkName = layerElems[i].outerHTML;

					layer.className = 'atvImg-rendered-layer';
					layer.setAttribute('data-layer',i);
					layer.innerHTML = linkName;
					layersHTML.appendChild(layer);

					layers.push(layer);
				}

				containerHTML.appendChild(shadowHTML);
				containerHTML.appendChild(layersHTML);
				containerHTML.appendChild(shineHTML);
				thisImg.appendChild(containerHTML);

				var w = thisImg.clientWidth || thisImg.offsetWidth || thisImg.scrollWidth;
				thisImg.style.transform = 'perspective('+ w*3 +'px)';

				if(supportsTouch){
					win.preventScroll = false;

					(function(_thisImg,_layers,_totalLayers,_shine) {
						thisImg.addEventListener('touchmove', function(e){
							if (win.preventScroll){
								e.preventDefault();
							}
							processMovement(e,true,_thisImg,_layers,_totalLayers,_shine);
						});
						thisImg.addEventListener('touchstart', function(e){
							win.preventScroll = true;
							processEnter(e,_thisImg);
						});
						thisImg.addEventListener('touchend', function(e){
							win.preventScroll = false;
							processExit(e,_thisImg,_layers,_totalLayers,_shine);
						});
					})(thisImg,layers,totalLayerElems,shineHTML);
				} else {
					(function(_thisImg,_layers,_totalLayers,_shine) {
						thisImg.addEventListener('mousemove', function(e){
							processMovement(e,false,_thisImg,_layers,_totalLayers,_shine);
						});
						thisImg.addEventListener('mouseenter', function(e){
							processEnter(e,_thisImg);
						});
						thisImg.addEventListener('mouseleave', function(e){
							processExit(e,_thisImg,_layers,_totalLayers,_shine);
						});
					})(thisImg,layers,totalLayerElems,shineHTML);
				}
			}

			function processMovement(e, touchEnabled, elem, layers, totalLayers, shine){

				var bdst = bd.scrollTop || htm.scrollTop,
					bdsl = bd.scrollLeft,
					pageX = (touchEnabled)? e.touches[0].pageX : e.pageX,
					pageY = (touchEnabled)? e.touches[0].pageY : e.pageY,
					offsets = elem.getBoundingClientRect(),
					w = elem.clientWidth || elem.offsetWidth || elem.scrollWidth, // width
					h = elem.clientHeight || elem.offsetHeight || elem.scrollHeight, // height
					wMultiple = 320/w,
					offsetX = 0.52 - (pageX - offsets.left - bdsl)/w, //cursor position X
					offsetY = 0.52 - (pageY - offsets.top - bdst)/h, //cursor position Y
					dy = (pageY - offsets.top - bdst) - h / 2, //@h/2 = center of container
					dx = (pageX - offsets.left - bdsl) - w / 2, //@w/2 = center of container
					yRotate = (offsetX - dx)*(0.07 * wMultiple), //rotation for container Y
					xRotate = (dy - offsetY)*(0.1 * wMultiple), //rotation for container X
					imgCSS = 'rotateX(' + xRotate + 'deg) rotateY(' + yRotate + 'deg)', //img transform
					arad = Math.atan2(dy, dx), //angle between cursor and center of container in RAD
					angle = arad * 180 / Math.PI - 90; //convert rad in degrees

				//get angle between 0-360
				if (angle < 0) {
					angle = angle + 360;
				}

				//container transform
				if(elem.firstChild.className.indexOf(' over') != -1){
					imgCSS += ' scale3d(1.07,1.07,1.07)';
				}
				elem.firstChild.style.transform = imgCSS;

				//gradient angle and opacity for shine
				shine.style.background = 'linear-gradient(' + angle + 'deg, rgba(255,255,255,' + (pageY - offsets.top - bdst)/h * 0.4 + ') 0%,rgba(255,255,255,0) 80%)';
				shine.style.transform = 'translateX(' + (offsetX * totalLayers) - 0.1 + 'px) translateY(' + (offsetY * totalLayers) - 0.1 + 'px)';

				//parallax for each layer
				var revNum = totalLayers;
				for(var ly=0;ly<totalLayers;ly++){
					layers[ly].style.transform = 'translateX(' + (offsetX * revNum) * ((ly * 2.5) / wMultiple) + 'px) translateY(' + (offsetY * totalLayers) * ((ly * 2.5) / wMultiple) + 'px)';
					revNum--;
				}
			}

			function processEnter(e, elem){
				elem.firstChild.className += ' over';
			}

			function processExit(e, elem, layers, totalLayers, shine){

				var container = elem.firstChild;

				container.className = container.className.replace(' over','');
				container.style.transform = '';
				shine.style.cssText = '';

				for(var ly=0;ly<totalLayers;ly++){
					layers[ly].style.transform = '';
				}

			}

		}

		atvImg();
	})();

});
